import React from "react"

import Layout from "../../../components/layout"
import SEO from "../../../components/seo"



const Singlebridge = () => {
  //javascript
  const title = 'Single Bridge (Detailed)';
  const metatitle = 'Single Bridge (Detailed)';
  const description = 'Meta Description for Single Bridge (Detailed)';
  const metadescription = description;
  return(
    <Layout bodyclass="single-bridge" title={title}>
      <SEO
        title={metatitle}
        description={metadescription}
      />
      <p></p>
      
    </Layout>
  )
}

export default Singlebridge
